import React, { Component } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
    BsGlobe
} from 'react-icons/bs';

import {
    BiPhoneCall
} from 'react-icons/bi';

import {
    MdEmail
} from 'react-icons/md';

export class Contact extends Component {
    constructor(props) {
        super(props)

        this.state = {
            name: '',
            email: '',
            message: ''
        }

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(e) {
        e.preventDefault();
       
        let thisClass = this;

        var formData = new FormData();
        formData.set('name', this.state.name);
        formData.set('email', this.state.email);
        formData.set('message', this.state.message);

        var xhr = new XMLHttpRequest();
        xhr.open("POST", "https://usebasin.com/f/a1960296ed76 ", true);
        xhr.send(formData);
        xhr.onload = function(e) {
            if (xhr.status === 200) {
                toast.success('Message posted', {
                    position: toast.POSITION.BOTTOM_RIGHT,
                })

                thisClass.setState({
                    name: '',
                    email: '',
                    message: ''
                });

            } else {
                toast.error('error posting message', {
                    position: toast.POSITION.BOTTOM_RIGHT,
                })
            }
        };
    }

    handleChange(e, key) {
        let updates = {}
        updates[key] =  e.target.value
        this.setState(updates);
    }



    render() {

        return (
            <section className="wrap" id="Contact">

                <svg width="0" height="0">
                <linearGradient id="blue-gradient" x1="100%" y1="100%" x2="0%" y2="0%">
                    <stop stopColor="#0054cc" offset="0%" />
                    <stop stopColor="#7f449e" offset="100%" />
                </linearGradient>
                </svg>

                <div className="container">
                    <div className="contact-form-sec">
                        <div className="row">
                            <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                                <div className="contact-info">
                                    <div className="title">
                                        <h4>Contact Info</h4>
                                        <p>Give us a call or drop by anytime, we endeavour to answer all enquires within 24 hours on business
                                            days.
                                            We will be happy to answer your questions.</p>
                                    </div>
                                    <div className="fotr-media">
                                        <div className="media">
                                            <div className="media-left">
                                                <div className="fotr-media-icon">
                                                    <BsGlobe style={{ fill: "url(#blue-gradient)" }} size="2.2rem"/>
                                                </div>
                                            </div>
                                            <div className="media-body">
                                                <h6>Our Address:</h6>
                                                <p>C 041A, Supermart 1, DLF Phase 4<br/> Gurgaon, Haryana-122002, India </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="fotr-media">
                                        <div className="media">
                                            <div className="media-left">
                                                <div className="fotr-media-icon">
                                                    <MdEmail style={{ fill: "url(#blue-gradient)" }} size="2.5rem"/>
                                                </div>
                                            </div>
                                            <div className="media-body">
                                                <h6>Our Mailbox:</h6>
                                                <p><a href="mailto:hello@filtron.ai">hello@filtron.ai</a></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="fotr-media">
                                        <div className="media">
                                            <div className="media-left">
                                                <div className="fotr-media-icon"> 
                                                    <BiPhoneCall style={{ fill: "url(#blue-gradient)" }} size="2.5rem"/>
                                                </div>
                                            </div>
                                            <div className="media-body">
                                                <h6>Our Phone:</h6>
                                                <p><a href="tel:+919810017873">+91 98100 17873</a> </p>
                                                <p><a href="tel:+919971219082">+91 99712 19082</a> </p>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>
                            <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                                <div className="contact-form">
                                    <div className="title">
                                        <h3>Ready to Get Started</h3>
                                        <p>Your email address will not be published. Required fields are marked*</p>
                                    </div>
                                    <form onSubmit={this.handleSubmit}>
                                        <div className="form-group">
                                            <input type="text" name="name" id="name" 
                                                className="form-ctrl form-control" 
                                                placeholder="Your Name" 
                                                value={this.state.name}
                                                onChange={(e) => this.handleChange(e, 'name')} />
                                        </div>
                                        <div className="form-group">
                                            <input type="text" name="email" id="email" 
                                                className="form-ctrl form-control" 
                                                placeholder="Your Email*" 
                                                value={this.state.email}
                                                onChange={(e) => this.handleChange(e, 'email')}
                                                required/>
                                        </div>

                                        <div className="form-group">
                                            <textarea rows="4" name="message" id="message" 
                                                className="form-control form-ctrl" 
                                                value={this.state.message}
                                                onChange={(e) => this.handleChange(e, 'message')}
                                                placeholder="Message"></textarea>
                                        </div>

                                        <button type="submit" className="button-1">Send Message </button>

                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer />
            </section>
        )
    }
}

export default Contact