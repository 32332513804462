import React, { Component } from 'react'

export class HeroSection extends Component {
    render() {
        return (
            <>
                <section className="slider-wrap">
                    <img alt='' src="images/banner.jpg" />
                    <div className="slide-text">
                        <div className="container">
                            <div className="row text-center">
                                <div className="col-12 col-sm-12 col-md-12">
                                    <h1>Delivering Value Through AI & Data </h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="banner-btm-sec">
                    <div className="container">
                        <ul>
                            <li>
                                <h4>Artificial Intelligence</h4>
                                <p>Building Ai-powered solutions</p>
                            </li>
                            <li>
                                <h4>Advanced Analytics</h4>
                                <p>Drive insights from your data</p>
                            </li>
                            <li>
                                <h4>Consulting</h4>
                                <p>Bringing capabilitites and consultative approach</p>
                            </li>
                            <li>
                                <h4>Smart Cameras</h4>
                                <p>Video feeds <br />For actionable triggers</p>
                            </li>
                        </ul>
                    </div>
                </section>
            </>
        )
    }
}

export default HeroSection