import React, { Component } from 'react'

export class Services extends Component {
    render() {
        return (
            <section className="wrap" id="Services">
                <div className="container">
                    <div className="title text-center">
                        <h6>Our Expertise</h6>
                        <h3>World-Class Solutions for your Business</h3>
                        <p>We are a specialist in building custom products in the areas of Computer Vision and Video Cameras.</p>
                    </div>
                    <div className="row">
                        <div className="col-12 col-sm-6 col-md-4 col-lg-4">
                            <div className="srvs-item">
                                <div className="srvs-icon"><img alt='' src="images/service-icon-1.png" /></div>
                                <h4>Security & Ai Powered Surveillance Industry Specialist </h4>
                                <p>Experience with major OEMs for Facial Recognition, VA, VMS, Cameras and Physical Access Systems, iOT,
                                    Drones</p>
                                <p>Deep Domain Knowledge </p>
                                <p>Experience working with top System Integrators/Brands </p>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-md-4 col-lg-4">
                            <div className="srvs-item">
                                <div className="srvs-icon"><img alt='' src="images/service-icon-2.png" /></div>
                                <h4>AI Consulting<br/></h4>
                                <p>Solve complex business processes using advances in Machine Learning, Computer Vision, NLP </p>
                                <p>High Performance in actual live deployment </p>
                                {/* <p>Best in class AI pipeline and methodology </p> */}
                                <p>Deployment in diverse industries </p>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-md-4 col-lg-4">
                            <div className="srvs-item">
                                <div className="srvs-icon"><img alt='' src="images/service-icon-3.png" /></div>
                                <h4>Business Intelligence Services<br/></h4>
                                <p>Supporting client’s transformation into modern data driven organization </p>
                                <p>Extract knowledge from your data, integrate it into (ETL) Enterprise Data Warehouse (EDW) and turn it
                                    into daily insights with interactive dashboards </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default Services