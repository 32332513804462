import React, { Component } from 'react';
import { Link } from "react-scroll";

export class Header extends Component {
    render() {
        return (
            <header className="fixedHeader">
                <nav id="navbar-example2" className="navbar navbar-expand-lg sticky-top navbar-light bg-white">
                    <div className="container">
                        <a className="navbar-brand" href="index.html"><img className="top-logo-size" alt='' src="images/filtron-logo-top-left.png" /></a>
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown"
                            aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>

                        <div className="collapse navbar-collapse" id="navbarNavDropdown">
                            <ul className="navbar-nav ms-auto flex-nowrap d-flex">
                                <li className="nav-item">
                                    <Link to='Services' spy={true}
                                        smooth={true}
                                        offset={-120}
                                        duration={50}
                                        activeClass="active" className='nav-link'> Services </Link>
                                    {/* <a className="nav-link" href="#Services">Services </a> */}
                                </li>
                                <li className="nav-item">
                                    <Link to='Products' spy={true}
                                        smooth={true}
                                        offset={-120}
                                        duration={50}
                                        activeClass="active" className='nav-link'> Products </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to='Contact' spy={true}
                                        smooth={true}
                                        offset={-170}
                                        duration={50}
                                        activeClass="active" className='nav-link'> Contact </Link>
                                </li>
                            </ul>
                        </div>

                    </div>
                </nav>
            </header>
        )
    }
}

export default Header