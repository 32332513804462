// import { BrowserRouter as Router } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'popper.js/dist/popper.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';
import './App.css';
// import Routes from './Routes';
import PageLayout from './Layout/PageLayout';

function App() {
  return (
    <div>
      <PageLayout />
    </div>
  );
}

export default App;
