import React, { Component } from 'react'

export class Products extends Component {
    render() {
        return (
            <section className="wrap" id="Products">
                <div className="container">
                    <div className="title text-center">
                        <p>Ready to use products in multiple industries, both SaaS and on-prem variety.
                            No wait. Start extracting value today.</p>
                    </div>
                    <div className="row product-row">
                        <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                            <div className="product-img"><img alt='' src="images/product-0.png" /></div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                            <div className="product-dec">
                                <h4>Attendance Product in <span style={{color: '#202D9F'}}>Schools & Offices</span></h4>
                                <ul className="list-points">
                                    <li>For Offices & On-the-Move, Field Teams using Face as Key</li>
                                    <li>For Schools & Colleges</li>
                                    <li>For Dorms & Data Centers </li>
                                    <li>Anti-Spoofing with rgb Liveliness Detection</li>
                                    <li>Using World-class Algorithms captures age, gender & sentiments</li>
                                </ul>
                            </div>
                        </div>

                    </div>
                    <div className="row product-row">
                        <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                            <div className="product-dec">
                                <h4>People Counting in <span style={{color: '#202D9F'}}>Public Spaces </span></h4>
                                <ul className="list-points">
                                    <li>Strategically placed cameras capture face, gender and Shirt Color to find unique face count </li>
                                    <li>Count is matched with sold tickets, to find gaps and stop revenue pilferage </li>
                                    <li>Also useful for demographic analysis </li>
                                    <li>Solution, works offline & and data is stores at bus depot at end of journey </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                            <div className="product-img"><img alt='' src="images/product-img0.jpg" /></div>
                        </div>
                    </div>
                    <div className="row product-row">
                        <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                            <div className="product-img"><img alt='' src="images/product-img1.jpg" /></div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                            <div className="product-dec">
                                <h4>Authentication & <span style={{color: '#202D9F'}}>Access Control</span></h4>
                                <ul className="list-points">
                                    <li>Suitable for Granting Access to Amusement Park/Events </li>
                                    <li>Face is registered During Booking </li>
                                    <li>Access to Park is granted using </li>
                                    <li>Face as ticket </li>
                                    <li>Also used for demography </li>
                                    <li>Analysis, overcrowding, heatmap</li>
                                    {/* <li>Can Work on Android based </li>
                                    <li>Solution or Linux/Windows based</li>
                                    <li>Integrated to CCTV </li>
                                    <li>Uses World Class Algorithm </li> */}
                                </ul>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        )
    }
}

export default Products