import React, { Component } from 'react';
// import { Outlet } from 'react-router-dom';

import Header from './Header';
import HeroSection from '../pages/HeroSection';
import Services from '../pages/Services';
import Products from '../pages/Products';
import Contact from '../pages/Contact';
import Footer from './Footer';

export class PageLayout extends Component {

    render() {
        return (
            <>
                <Header />
                <HeroSection />
                <Services />
                <Products />
                <Contact />
                <Footer />
            </>
        )
    }
}

export default PageLayout;