import React, { Component } from 'react'

export class Footer extends Component {
    render() {
        return (
            <footer>
                <div className="container">
                    <div className="copyright">
                        <p>Copyrights © 2023 Filtron | All Rights Reserved</p>
                    </div>
                </div>
            </footer>
        )
    }
}

export default Footer